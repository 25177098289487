import React, { useState, useEffect } from "react";
import { Container, Typography, Box, IconButton } from "@mui/material";
import { Helmet } from "react-helmet";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import "./LandingPage.css";

function Header({ darkMode, toggleDarkMode }) {
  return (
    <header className="header">
      <IconButton onClick={toggleDarkMode} color="inherit">
        {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </header>
  );
}

function Footer() {
  return (
    <footer className="footer">
      <Typography variant="body2">
        &copy; 2024 M4Code Sistemas LTDA. Todos os direitos reservados.
      </Typography>
    </footer>
  );
}

function LandingPage() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode === "true" || false;
  });

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className="landing-page">
      <Helmet>
        <title>m4code</title>
        <meta name="description" content="Descrição da sua landing page" />
      </Helmet>
      <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <Container className="landing-container">
        <Box textAlign="center" py={5}>
          <Typography variant="h1" className="landing-title">
            M4CODE
          </Typography>
        </Box>
        <Box textAlign="center" py={3}>
          <Typography variant="body1" className="landing-description">
            Site em construção.
          </Typography>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default LandingPage;
